'use client';

import { Button, Dropdown } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import LanguageSwitcher from '../common/LanguageSwitcher';
import Link from 'next/link';
import { Locale } from '../../../i18n-config';
import { usePathname } from 'next/navigation';
import useScrollListener from '@/hooks/useScrollListener';
import { useTranslation } from '@/i18n/client';
import { useWindowSize } from '@/hooks/useWindowSize';

type Props = {
  language: Locale;
};

const Header = ({ language }: Props) => {
  const { t } = useTranslation(language);
  const pathName = usePathname();
  const scroll = useScrollListener();
  const [width] = useWindowSize();

  const headerRef = useRef<HTMLElement | null>(null);

  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [shouldNavBeHidden, setShouldNavBeHidden] = useState(false);

  const links: Array<{ title: string; href: string; subLinks?: Array<{ title: string; href: string }> }> = [
    {
      title: t('Layout/Header/key1'),
      href: `/${language}/features`,
    },
    // {
    //   title: t('Layout/Header/key2'),
    //   href: `/${language}/company`,
    // },
    // {
    //   title: t('Layout/Header/key3'),
    //   href: `/${language}/support`,
    //   subLinks: [
    //     {
    //       title: t('Layout/Footer/key12'),
    //       href: `/${language}/contact-us`,
    //     },
    //     {
    //       title: t('Layout/Footer/key13'),
    //       href: `/${language}/faq`,
    //     },
    //     {
    //       title: t('Layout/Footer/key10'),
    //       href: `/${language}/privacy-policy`,
    //     },
    //   ],
    // },
    {
      title: t('Layout/Header/key10'),
      href: `/${language}/loyalty-programs`,
    },
    {
      title: t('Layout/Header/key4'),
      href: `/${language}/pricing`,
    },
  ];

  const authLinks = [
    {
      label: 'Login',
      title: t('Layout/Header/key5'),
      href: `${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/login`,
    },
    {
      label: 'Register',
      title: t('Layout/Header/key6'),
      href: `${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/register`,
    },
  ];

  const authClassName: { [key: string]: string } = {
    Login: ' bg-gray-700 text-white',
    Register: 'bg-gray-100 text-gray-950',
  };

  // useEffect(() => {
  //   if (width > 768 && scroll.y > 150 && scroll.y - scroll.lastY > 0) setShouldNavBeHidden(true);
  //   else setShouldNavBeHidden(false);
  // }, [scroll.y, scroll.lastY, width]);

  // Checks for each link click on mobile to close menu
  useEffect(() => {
    const rootEl = document.querySelector('#mobile-menu');

    if (rootEl) {
      const links = rootEl.querySelectorAll('a');

      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          setIsMobileMenuOpen(false);
        });
      });
    }

    return () => {};
  }, []);

  // Checks for click outside of mobile menu to close it
  useEffect(() => {
    const handleClick = (event: any) => {
      if (!headerRef?.current?.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  // ${shouldNavBeHidden ? 'is-hidden' : ''}

  return (
    <header
      className={`sticky top-0 z-10 min-h-[70px] transition-all 
      ${isMobileMenuOpen ? 'bg-gray-950' : 'bg-gray-950'}`}
      ref={headerRef}
    >
      <div className={`container max-w-full sm:py-4 py-6 relative`}>
        <div className="flex items-center gap-10">
          <Link
            href={`/`}
            className="logo-area flex gap-3 items-center"
            onClick={() => {
              if (isMobileMenuOpen) setIsMobileMenuOpen(false);
            }}
          >
            <div className="icon shrink-0">
              <Image
                src={
                  isLogoHovered
                    ? 'https://cdn.1cdn.app/application/UMD/24073010001525_On_Hover_logo.svg'
                    : 'https://cdn.1cdn.app/application/UMD/24072109215122_Logo.svg'
                }
                width={0}
                height={0}
                sizes="100vw"
                alt="UMD"
                className="w-full h-full"
                onMouseEnter={() => setIsLogoHovered(true)}
                onMouseLeave={() => setIsLogoHovered(false)}
              />
            </div>
            {/* <div className="text-white text-2xl font-bold font-['Figtree'] leading-loose">Super User Manager</div> */}
          </Link>

          <div className="links-area lg:flex hidden gap-10 items-center">
            {links.map((link) => {
              if (!link.subLinks) {
                return (
                  <Link
                    key={link.title}
                    href={link.href}
                    title={link.title}
                    className={`text-white text-base font-normal font-['Figtree'] leading-normal ${
                      pathName === link.href ? 'active' : ''
                    }`}
                  >
                    {link.title}
                  </Link>
                );
              } else {
                return (
                  <Dropdown
                    key={link.title}
                    label=""
                    renderTrigger={() => (
                      <span
                        className={`flex items-center gap-2 text-white text-base font-normal font-['Figtree'] leading-normal cursor-pointer ${
                          link.subLinks?.find((item) => item.href === pathName) ? 'active' : ''
                        }`}
                        aria-label="support"
                        role="menu"
                        id="support"
                        title={link.title}
                        aria-busy
                      >
                        {link.title}
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      </span>
                    )}
                    className={`${language.includes('en-') ? 'w-[180px]' : 'w-[210px]'} bg-gray-900 border-gray-900`}
                  >
                    {link.subLinks.map((sub) => (
                      <Dropdown.Item
                        aria-label={sub.title}
                        key={sub.title}
                        as={Link}
                        href={sub.href}
                        className={`${
                          language.includes('en-') ? 'w-[180px]' : 'w-[210px]'
                        } px-4 py-2 justify-start items-center gap-3 inline-flex text-gray-100 hover:bg-transparent focus:bg-transparent`}
                        role="menuitem"
                      >
                        {sub.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                );
              }
            })}
          </div>

          <div className="ms-auto lg:flex lg:items-center lg:gap-3 hidden">
            <LanguageSwitcher backgroundColor="bg-gray-950" />

            <div className="extra-links flex gap-3">
              {authLinks.map((authLink) => (
                <Button
                  as={Link}
                  key={authLink.title}
                  href={authLink.href}
                  color={authLink.label === 'Register' ? 'light' : 'dark'}
                >
                  {authLink.title}
                </Button>
              ))}
            </div>
          </div>

          {/* Small screen menu trigger */}
          <div className="ms-auto lg:hidden flex flex-col justify-center">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              name="menuBtn"
              aria-label="menuBtn"
              title="menu-button"
            >
              {isMobileMenuOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="#9CA3AF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="#9CA3AF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`lg:hidden transition-all py-4 ${isMobileMenuOpen ? 'block' : 'hidden'} bg-gray-900`}
        id="mobile-menu"
      >
        <div className="flex-col justify-start items-start gap-8 flex px-5">
          {links.map((link) => {
            if (!link.subLinks) {
              return (
                <Link
                  key={link.title}
                  href={link.href}
                  title={link.title}
                  className={`text-white text-base font-normal font-['Figtree'] leading-normal ${
                    pathName === link.href ? 'active' : ''
                  }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {link.title}
                </Link>
              );
            } else {
              return null;
              // <Dropdown
              //   key={link.title}
              //   label=""
              //   renderTrigger={() => (
              //     <span
              //       className={`flex items-center gap-2 text-white text-base font-normal font-['Figtree'] leading-normal cursor-pointer ${
              //         link.subLinks.find((item) => item.href === pathName) ? 'active' : ''
              //       }`}
              //       aria-label="support"
              //       role="menu"
              //       id="support"
              //       title={link.title}
              //       aria-busy
              //     >
              //       {link.title}
              //       <svg
              //         width="10"
              //         height="6"
              //         viewBox="0 0 10 6"
              //         fill="none"
              //         xmlns="http://www.w3.org/2000/svg"
              //         className="ml-2"
              //       >
              //         <path
              //           fillRule="evenodd"
              //           clipRule="evenodd"
              //           d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
              //           fill="#9CA3AF"
              //         />
              //       </svg>
              //     </span>
              //   )}
              //   className={`${language.includes('en-') ? 'w-[180px]' : 'w-[210px]'} bg-gray-900 border-gray-900`}
              // >
              //   {link.subLinks.map((sub) => (
              //     <Dropdown.Item
              //       aria-label={sub.title}
              //       key={sub.title}
              //       as={Link}
              //       href={sub.href}
              //       className={`${
              //         language.includes('en-') ? 'w-[180px]' : 'w-[210px]'
              //       } px-4 py-2 justify-start items-center gap-3 inline-flex text-gray-100 hover:bg-transparent focus:bg-transparent`}
              //       role="menuitem"
              //     >
              //       {sub.title}
              //     </Dropdown.Item>
              //   ))}
              // </Dropdown>
            }
          })}

          {/* <div className="justify-center items-center inline-flex">
            <Link
              href={`/${language}/features#auth-status-control`}
              className="p-3 justify-start items-center gap-4 flex"
            >
              <div className="w-6 h-6 shrink-0 relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
                    stroke="#0EA5E9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="text-white text-base font-normal font-['Figtree'] leading-normal">
                {t('Layout/Footer/key3')}
              </span>
            </Link>
          </div>
          <div className="justify-center items-center inline-flex">
            <Link
              href={`/${language}/features#auth-templates`}
              className="p-3 justify-start items-center gap-4 inline-flex"
            >
              <div className="w-6 h-6 shrink-0 relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.75 17L9 20L8 21H16L15 20L14.25 17M3 13H21M5 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17Z"
                    stroke="#0EA5E9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="text-white text-base font-normal font-['Figtree'] leading-normal">
                {t('Layout/Footer/key4')}
              </span>
            </Link>
          </div>
          <div className="justify-center items-center inline-flex">
            <Link
              href={`/${language}/features#loyalty-membership-card`}
              className="p-3 justify-start items-center gap-4 inline-flex"
            >
              <div className="w-6 h-6 shrink-0 relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M17 20H22V18C22 16.3431 20.6569 15 19 15C18.0444 15 17.1931 15.4468 16.6438 16.1429M17 20H7M17 20V18C17 17.3438 16.8736 16.717 16.6438 16.1429M7 20H2V18C2 16.3431 3.34315 15 5 15C5.95561 15 6.80686 15.4468 7.35625 16.1429M7 20V18C7 17.3438 7.12642 16.717 7.35625 16.1429M7.35625 16.1429C8.0935 14.301 9.89482 13 12 13C14.1052 13 15.9065 14.301 16.6438 16.1429M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM21 10C21 11.1046 20.1046 12 19 12C17.8954 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8C20.1046 8 21 8.89543 21 10ZM7 10C7 11.1046 6.10457 12 5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8C6.10457 8 7 8.89543 7 10Z"
                    stroke="#0EA5E9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="text-white text-base font-normal font-['Figtree'] leading-normal">
                {t('Layout/Footer/key5')}
              </span>
            </Link>
          </div> */}
        </div>
        <div className="w-full px-3 py-6 flex-col justify-start items-start gap-6 inline-flex mt-4">
          {/* <div className="grid grid-cols-12 w-full">
            <div className="col-span-6 flex flex-col gap-4">
              <Link
                  href={`/${language}/trust-center`}
                  className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
                >
                  {t('Layout/Footer/key9')}
                </Link>
              <Link
                href={`/${language}/privacy-policy`}
                className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
              >
                {t('Layout/Footer/key10')}
              </Link>
              <Link
                  href={`/${language}/careers`}
                  className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
                >
                  {t('Layout/Footer/key8')}
                </Link>
              <Link
                href={`/${language}/faq`}
                className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
              >
                {t('Layout/Footer/key13')}
              </Link>
            </div>
            <div className="col-span-6 flex flex-col gap-4">
              <Link
                href={`/${language}/about-us`}
                className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
              >
                {t('Layout/Footer/key7')}
              </Link>
              <Link
                href={`/${language}/contact-us`}
                className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-normal"
              >
                {t('Layout/Footer/key12')}
              </Link>
              <Link
                href={`/${language}/pricing`}
                className="justify-start items-center inline-flex text-white text-base font-normal font-['Figtree'] leading-norma"
              >
                {t('Layout/Header/key4')}
              </Link>
            </div>
          </div> */}

          <Button
            as={Link}
            href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/register`}
            color={'light'}
            className="w-full"
          >
            <span className="text-gray-950 text-base font-normal font-['Figtree'] leading-normal">
              {t('Layout/Header/key6')}
            </span>
          </Button>

          <div className="self-stretch justify-center items-center gap-1 inline-flex">
            <div className="text-gray-300 text-base font-normal font-['Figtree'] leading-normal">
              {t('Layout/Header/Key7')}
            </div>
            <Link
              href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/login`}
              className="justify-start items-center flex"
            >
              <span className="text-sky-300 text-base font-normal font-['Figtree'] leading-normal">
                {t('Layout/Header/key5')}
              </span>
            </Link>
          </div>

          <div className="self-stretch justify-center items-center inline-flex my-3">
            <LanguageSwitcher backgroundColor="bg-gray-900" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
