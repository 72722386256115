'use client';

import {Locale, i18n as i18nConfig} from '../../../i18n-config';
import {useParams, usePathname, useRouter} from 'next/navigation';
import {Dropdown} from 'flowbite-react';
import Image from 'next/image';
import React from 'react';
import {useTranslation} from '@/i18n/client';

const LanguageMapper = {
    'en-US': 'English',
    'fr-FR': 'Français',
} as { [key: string]: string };

const LanguageSwitcher = ({backgroundColor}: { backgroundColor?: string }) => {
    const router = useRouter();
    const params = useParams();
    const pathName = usePathname();
    const {i18n} = useTranslation(params?.lang as Locale);

    const selectHandler = (locale: string) => {
        if (!pathName) return '/en-US';
        const segments = pathName.split('/');
        segments[1] = locale;
        return segments.join('/');
    };

    const handleClick = (url: string) => {
        router.push(url);
    };

    const lngFlagMapper = {
        en: 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
        'en-US': 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
        'en-CH': 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
        'en-EU': 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
        fr: 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
        // 'fr-US': 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
        'fr-FR': 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
        'fr-CH': 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
    } as { [key: string]: string };

    return (
        <Dropdown
            label=""
            renderTrigger={() => (
                <div
                    className={`pl-1 pr-1.5 py-1 ${
                        backgroundColor ? backgroundColor : 'bg-gray-800'
                    } rounded-[99px] justify-start items-center inline-flex gap-2`}
                    role="menu"
                    title="Language dropdown"
                    aria-busy
                >
                    <Image
                        src={lngFlagMapper[params?.lang as string]}
                        alt={params?.lang as string}
                        width={28}
                        height={28}
                        className="rounded-full shrink-0"
                        title={(params?.lang as string) + ' flag'}
                    />

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="shrink-0"
                    >
                        <path d="M7.9987 11.3333L12.6654 6H3.33203L7.9987 11.3333Z" fill="#9CA3AF"/>
                    </svg>
                </div>
            )}
        >
            {i18nConfig.locales
                .filter((item) => item === 'en-US' || item === 'fr-FR')
                .map((l) => (
                    <span
                        onClick={() => {
                            handleClick(selectHandler(l));
                        }}
                        className="d-block tw-cursor-pointer"
                        role="menuitem"
                        key={l}
                    >
                    <Dropdown.Item as={'span'}>
                  {LanguageMapper[l]}
                    </Dropdown.Item>
                    </span>
                ))}
        </Dropdown>
    );
};

export default LanguageSwitcher;
