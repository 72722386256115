'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

const ScrollReset = () => {
  const pathName = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return null;
};

export default ScrollReset;
