'use client';

import { fallbackLng, locales } from '@/i18n/settings';
import { useCallback, useEffect } from 'react';
import { useParams, usePathname } from 'next/navigation';
import { Locale } from '../../../i18n-config';
import { OptionsType } from 'cookies-next/lib/types';
import { setCookie } from 'cookies-next';
import { useTranslation } from '@/i18n/client';

const LanguageInitiator = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();
  const pathname = usePathname();
  const { i18n } = useTranslation(params?.lang as Locale);

  const cookieOptions = useCallback(() => {
    return {
      domain:
        location.hostname.split('.').length > 2
          ? '.' +
            location.hostname
              .split('.')
              .slice(location.hostname.split('.').length - 2, location.hostname.split('.').length)
              .join('.')
          : '.' + location.hostname,
      secure: true,
      sameSite: 'none',
      maxAge: 60 * 60 * 24 * 365, // 1 year
    } as OptionsType;
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      if (locales.includes(lng as any)) setCookie('NEXT_LOCALE', lng, cookieOptions());
      else setCookie('NEXT_LOCALE', fallbackLng, cookieOptions());
    });
  }, [cookieOptions, i18n]);

  useEffect(() => {
    if (
      typeof localStorage !== 'undefined' &&
      localStorage.getItem('_sum_landing_currency') &&
      !pathname?.includes('/pricing')
    ) {
      localStorage.removeItem('_sum_landing_currency');
    }
  }, [pathname]);

  return children;
};

export default LanguageInitiator;
